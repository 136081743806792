body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textTitle {
    font-size: 30px;
}
.container {
    text-align: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    position: relative;
    top: 0px;
    bottom: 0px;
  }

.ant-menu-item-selected {
    color: #ffffff;
}

.h100 {
  height: 100%;
}

.ant-form-explain {
    display: flex;
}
.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*background-color: #565db2;*/
}

.login-form {
    width: 100%;
}

.loginContent {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0px 8%;
    width: 30%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.formItem {
    width: 100%;
}

.submit {
    width: 30%;
    height: 40px;
    background-color: #000000;
    border-color: #000000;
}

.title {
    font-family: sans-serif;
    font-size: 50px;
    font-style: italic;
    color: rgb(255, 255, 255);
    margin-left: 200px;
    margin-bottom: 200px;
}
.bg-img {
    width: 70%;
    height: 100%;
}
.bg-img img {
    width: 100%;
    height: 100%;
}
.up-title,.title-img,.title-img img {
    width: 100%;
}
.title-font {
    text-align: left;
    margin-top: 2%;
    color: rgba(0,0,0,0.5);
    font-weight: 400;
    font-size: 14px;
}
.ant-row {
    text-align: left;
}

.body {
    margin: 0 0 0 0;
}
.h100 {
    height: 100%;
    padding-top: 55px;
}

.ant-breadcrumb {
    margin-top: -43px !important;
    margin-left: 35px !important;
    font-size: 15px !important;
}

.content {
    /* height: -webkit-fill-available; */
    margin-top: -4px;
    height: 100%;
}


.mysider {
    background: #ffffff;
    /* height: -webkit-fill-available; */
}

.mymenu {
    height: 100%;
}

.right-content {
}
.ant-menu-item-selected  {
    background-color: #6599ff !important;
    color: #ffffff;
}

.ant-menu-item-selected:hover {
    color:#ffffff !important;
}

.ant-menu-item-selected  > a:hover{
    color:#ffffff !important;
}

.ant-menu-item-selected  > a{
    color:#ffffff !important;
}

.header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #5966c6;
    color: #ffffff;
    padding: 10px 0 10px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.header-title {
    margin-left: 20px;
    font-size: 25px;
    font-weight: 800;
}

.icon-text-margin {
    margin-left: 5px;
}

.user-info {
    position: absolute;
    right: 0;
    margin-right: 120px;
}

.logout {
    position: absolute;
    right: 0;
    margin-right: 20px;
}
.ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
}

.toolbar {
    display: flex;
    margin-bottom: 10px;
}
.search {
    position: absolute;
    width: auto;
    right: 0px;
    display: inline;
    margin-right: 30px;
}

.filter {
    width: 100px;
}
.right {
    position: absolute;
    right: 40px;
    margin-top: 20px;
}
