.ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
}

.toolbar {
    display: flex;
    margin-bottom: 10px;
}
.search {
    position: absolute;
    width: auto;
    right: 0px;
    display: inline;
    margin-right: 30px;
}

.filter {
    width: 100px;
}