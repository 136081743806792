
.ant-form-explain {
    display: flex;
}
.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*background-color: #565db2;*/
}

.login-form {
    width: 100%;
}

.loginContent {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0px 8%;
    width: 30%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.formItem {
    width: 100%;
}

.submit {
    width: 30%;
    height: 40px;
    background-color: #000000;
    border-color: #000000;
}

.title {
    font-family: sans-serif;
    font-size: 50px;
    font-style: italic;
    color: rgb(255, 255, 255);
    margin-left: 200px;
    margin-bottom: 200px;
}
.bg-img {
    width: 70%;
    height: 100%;
}
.bg-img img {
    width: 100%;
    height: 100%;
}
.up-title,.title-img,.title-img img {
    width: 100%;
}
.title-font {
    text-align: left;
    margin-top: 2%;
    color: rgba(0,0,0,0.5);
    font-weight: 400;
    font-size: 14px;
}
.ant-row {
    text-align: left;
}
